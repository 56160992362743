<template>
  <v-card class="overflow-hidden" tile elevation="0">
    <v-overlay
      v-show="state !== State.OPEN || state === State.BUFFERING"
      absolute
      class="text-center"
      color="black"
      opacity="0.85"
    >
      <template v-if="state === State.LOADING">
        <p class="my-4">Betöltés...</p>
        <v-progress-linear stream buffer-value="0" rounded height="5"/>
      </template>
      <template v-if="state === State.BUFFERING">
        <p class="my-4">Pufferelés: {{ bufferProcess }}%</p>
        <v-progress-linear
          stream
          :buffer-value="bufferProcess"
          :value="bufferProcess"
          rounded
          height="5"
        />
      </template>
      <template v-else-if="state === State.ERROR">
        <v-icon size="96" color="primary">mdi-alert-rhombus-outline</v-icon>
        <p class="my-4" v-html="errorMessage"></p>
        <v-progress-linear
          v-if="retryTimeout"
          v-model="retryIntervalProgress"
          rounded
          height="5"
        />
      </template>
    </v-overlay>
    <v-responsive :aspect-ratio="16 / 9" class="d-flex justify-center align-center">
      <v-expand-transition>
        <video
          v-show="state === State.OPEN"
          style="
            object-fit: fill;
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
            background: black;
            display: block;
            transition: 0.6s all, 0.2s max-height;
            transition-timing-function: ease-in-out;
          "
          :style="{ transform: `translate(0.5, 0.5) scale(1, ${state === State.OPEN ? 1 : 0.8})` }"
          ref="videoPlayer"
          autoplay
          muted
        ></video>
      </v-expand-transition>
    </v-responsive>
  </v-card>
</template>

<script>
const State = {
  LOADING: 'LOADING',
  BUFFERING: 'BUFFERING',
  ERROR: 'ERROR',
  OPEN: 'OPEN',
};

export default {
  props: {
    serverAddress: {
      type: String,
      required: true,
    },
    streamId: {
      type: String,
      required: true,
    },
    channel: {
      type: Number,
      default: 0,
    },
    cssFilters: {
      type: String,
      default: 'saturate(0.6)',
    },
  },

  data() {
    return {
      State,
      state: State.LOADING,
      player: null,
      errorMessage: null,
      retryTimeout: null,
      retryIntervalInSeconds: 3,
      retryIntervalProgress: 0,
      exiting: false,
      bufferProcess: 0,
      bufferProcessAddition: 10,
      bufferProcessInterval: null,

      mseQueue: [],
      mseSourceBuffer: null,
      mseStreamingStarted: false,
      videoSound: false,
      webSocket: null,
    };
  },

  mounted() {
    this.play();
    window.addEventListener('focus', () => {
      if (this.$refs.videoPlayer) {
        if (this.video?.fastSeek) {
          this.video.fastSeek(Number.MAX_SAFE_INTEGER);
        } else {
          this.video.currentTime = Number.MAX_SAFE_INTEGER;
        }
      }
    });
  },

  methods: {
    play() {
      this.state = State.LOADING;
      this.retryIntervalProgress = 0;
      this.$refs.videoPlayer.onplay = (event) => {
        clearInterval(this.bufferProcessInterval);
        this.bufferProcess = 100;
        setTimeout(() => {
          this.state = State.OPEN;
        }, 50);
      };

      this.$refs.videoPlayer.addEventListener('loadeddata', () => {
        this.$refs.videoPlayer.play();
      });

      const url = `${this.serverAddress}/stream/${this.streamId}/channel/${this.channel}/mse?uuid=${this.streamId}&channel=${this.channel}`;

      let mse = new MediaSource();
      this.$refs.videoPlayer.src = window.URL.createObjectURL(mse);
      mse.addEventListener(
        'sourceopen',
        () => {
          this.webSocket = new WebSocket(url);
          this.webSocket.binaryType = 'arraybuffer';
          this.webSocket.onopen = (event) => {
            this.state = State.BUFFERING;
            this.bufferProcess = 0;
            this.bufferProcessInterval = setInterval(() => {
              this.bufferProcess += Math.floor(
                Math.random() * ((100 - this.bufferProcess) / 4) + (100 - this.bufferProcess) / 5
              );
              if (this.bufferProcess >= 100) {
                this.bufferProcess = 100;
                clearInterval(this.bufferProcessInterval);
              }
            }, 250);
          };
          this.webSocket.onclose = (event) => {
            this.state = State.ERROR;
            this.retryTimeout = setTimeout(this.retryIntervalEventHandler, 100);
            this.errorMessage = 'Hiba történt!<br>Újrapróbálkozás...';
          };
          this.webSocket.onmessage = (event) => {
            let data = new Uint8Array(event.data);
            if (data[0] == 9) {
              const decoded_arr = data.slice(1);
              let mimeCodec;
              if (window.TextDecoder) {
                mimeCodec = new TextDecoder('utf-8').decode(decoded_arr);
              } else {
                mimeCodec = Utf8ArrayToStr(decoded_arr);
              }
              if (mimeCodec.indexOf(',') > 0) {
                this.videoSound = true;
              }
              this.mseSourceBuffer = mse.addSourceBuffer('video/mp4; codecs="' + mimeCodec + '"');
              this.mseSourceBuffer.mode = 'segments';
              this.mseSourceBuffer.addEventListener('updateend', this.pushPacket);
            } else {
              this.readPacket(event.data);
            }
          };
        },
        false
      );
    },

    async handleNegotiationNeededEvent() {
      const url = `${this.serverAddress}/stream/${this.streamId}/channel/0/webrtc?uuid=${this.streamId}&channel=0`;
      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);

      const body = new URLSearchParams();
      body.append('data', btoa(this.peerConnection.localDescription.sdp));
      const result = await fetch(url, {
        method: 'POST',
        body,
      });
      const data = await result.text();
      try {
        this.peerConnection.setRemoteDescription(
          new RTCSessionDescription({
            type: 'answer',
            sdp: atob(data),
          })
        );
      } catch (e) {
        console.warn(e);
      }
    },

    pushPacket() {
      if (!this.mseSourceBuffer.updating) {
        if (this.mseQueue.length > 0) {
          const packet = this.mseQueue.shift();
          this.mseSourceBuffer.appendBuffer(packet);
        } else {
          this.mseStreamingStarted = false;
        }
      }
      if (this.$refs && this.$refs.videoPlayer.buffered.length > 0) {
        if (typeof document.hidden !== 'undefined' && document.hidden && !this.videoSound) {
          //no sound, browser paused video without sound in background
          this.$refs.videoPlayer.currentTime =
            this.$refs.videoPlayer.buffered.end(this.$refs.videoPlayer.buffered.length - 1) - 0.5;
        }
      }
    },

    readPacket(packet) {
      if (!this.mseStreamingStarted) {
        this.mseSourceBuffer.appendBuffer(packet);
        this.mseStreamingStarted = true;
        return;
      }
      this.mseQueue.push(packet);
      if (!this.mseSourceBuffer.updating) {
        this.pushPacket();
      }
    },

    retryIntervalEventHandler() {
      this.retryIntervalProgress += 100 / (this.retryIntervalInSeconds * 10);
      if (this.retryIntervalProgress < 100) {
        setTimeout(this.retryIntervalEventHandler, 100);
      } else {
        this.play();
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.webrtcSendChannelInterval);
    this.webSocket.close();
  },
};
</script>
