<template>
    <v-dialog
      :value="true"
      width="500"
    >
      <v-card>
        <v-card-title>
          Normál üzemmódra állítás
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close" small fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-datetime-picker
            timePicker
            datePicker
            v-model="backToNormalDatetime"
            :errorMessages="errors.back_to_normal_datetime"
            label="Kezdeti időpont"
            required
            filled
          />
        </v-card-text>

        <v-divider class="mb-0 pb-0"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            align="start"
            @click="save"
            :disabled="loading"
          >
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    elementId: {
      type: [Number, String, Boolean],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      backToNormalDatetime: null,
      errors: {},
    };
  },

  methods: {
    async save() {
      this.errors = {};
      if (!this.backToNormalDatetime) {
        this.errors.back_to_normal_datetime = 'Kötelező';
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }
      try {
        const response = await this.$http.post(`remote-open/default-operating-mode/${this.elementId}`, {
          element_id: this.normal_mode_popup_element_id,
          datetime: this.backToNormalDatetime,
        });
        if (response.status === "OK") {
          this.$emit('saved');
        }
      } catch (e) {
        console.error(e);
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
