var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.state !== _vm.State.OPEN || _vm.state === _vm.State.BUFFERING,
      expression: "state !== State.OPEN || state === State.BUFFERING"
    }],
    staticClass: "text-center",
    attrs: {
      "absolute": "",
      "color": "black",
      "opacity": "0.85"
    }
  }, [_vm.state === _vm.State.LOADING ? [_c('p', {
    staticClass: "my-4"
  }, [_vm._v("Betöltés...")]), _c('v-progress-linear', {
    attrs: {
      "stream": "",
      "buffer-value": "0",
      "rounded": "",
      "height": "5"
    }
  })] : _vm._e(), _vm.state === _vm.State.BUFFERING ? [_c('p', {
    staticClass: "my-4"
  }, [_vm._v("Pufferelés: " + _vm._s(_vm.bufferProcess) + "%")]), _c('v-progress-linear', {
    attrs: {
      "stream": "",
      "buffer-value": _vm.bufferProcess,
      "value": _vm.bufferProcess,
      "rounded": "",
      "height": "5"
    }
  })] : _vm.state === _vm.State.ERROR ? [_c('v-icon', {
    attrs: {
      "size": "96",
      "color": "primary"
    }
  }, [_vm._v("mdi-alert-rhombus-outline")]), _c('p', {
    staticClass: "my-4",
    domProps: {
      "innerHTML": _vm._s(_vm.errorMessage)
    }
  }), _vm.retryTimeout ? _c('v-progress-linear', {
    attrs: {
      "rounded": "",
      "height": "5"
    },
    model: {
      value: _vm.retryIntervalProgress,
      callback: function ($$v) {
        _vm.retryIntervalProgress = $$v;
      },
      expression: "retryIntervalProgress"
    }
  }) : _vm._e()] : _vm._e()], 2), _c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('v-expand-transition', [_c('video', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.state === _vm.State.OPEN,
      expression: "state === State.OPEN"
    }],
    ref: "videoPlayer",
    staticStyle: {
      "object-fit": "fill",
      "width": "100%",
      "height": "auto",
      "aspect-ratio": "16 / 9",
      "background": "black",
      "display": "block",
      "transition": "0.6s all, 0.2s max-height",
      "transition-timing-function": "ease-in-out"
    },
    style: {
      transform: `translate(0.5, 0.5) scale(1, ${_vm.state === _vm.State.OPEN ? 1 : 0.8})`
    },
    attrs: {
      "autoplay": "",
      "muted": ""
    },
    domProps: {
      "muted": true
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }